<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <div
            class="d-flex border border-info rounded p-2 justify-content-center"
          >
            <h4 style="font-size: 18px; font-weight: bolder">
              Survey Name:<span class="text-info"> {{ survey.name }}</span>
            </h4>
           
          </div>
          <br />
          <h6 class="text-danger" v-if="resultPostData.length == 0">
            Survey Questions not assigned yet, Please check back later!
          </h6>
          <b-form @submit.prevent="preparePostDataForSurveyResults()" v-else>
            <div class="choiceOptions">
              <b-overlay
                :show="answerSurvey"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="md"
              >
                <div
                  v-for="(choice, choiceIndex) in resultPostData"
                  :key="choiceIndex"
                >
                  <div class="border border-info rounded p-3 mb-3">
                    <b-row>
                      <b-col md="10">
                        <div
                          class="questionNoAndQuestion d-flex align-items-center"
                        >
                          <p style="font-size: 16px; font-weight: bolder">
                            {{ choice.question_no }}:
                          </p>
                          <p
                            style="font-size: 16px; font-weight: 600"
                            class="ml-2"
                          >
                            {{ choice.name }}
                          </p>
                        </div>
                      </b-col>
                      <b-col md="2">
                        <p style="font-size: 16px; font-weight: bolder">
                          Count
                        </p>
                      </b-col>
                    </b-row>

                    <div class="options">
                      <b-row
                        v-for="(option, optionIndex) in choice.choices"
                        :key="optionIndex"
                      >
                        <b-col md="10">
                          <p>{{ optionIndex + 1 }} . {{ option.name }}</p>
                        </b-col>
                        <b-col md="2">
                          <b-form-input
                            placeholder="Count"
                            type="text"
                            v-model="option.offline_evaluation_count"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </b-overlay>
              <!-- </div> -->
            </div>
            <br />
            <div class="submitAnswers text-center">
              <b-button
                variant="primary"
                size="md"
                type="submit"
                :disabled="resultBtn.disabled"
                >{{ resultBtn.text }}</b-button
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  async created() {
    await this.SurveyResult();
  },
  data() {
    return {
      fetchData: false,
      answerSurvey: false,
      survey: {},
      choices: [],
      selected: [],
      surveyData: [],
      resultPostData: [],
      courseTeacherCombination: [],
      resultBtn: {
        text: "Update",
        disabled: false,
      },
      questionsAndChoices: [], //testing------------
      answers: {}, // testing---------------
      surveyResults: {},
      resultArray: [],
      // coursePoAndPso: {},
    };
  },
  validations: {
    surveyAnswers: {
      required: requiredIf(function () {}),
    },
    resultPostData: {
      required,
    },
  },
  methods: {
    //********* Prepare Post Data for Survey Answers **********/
    async preparePostDataForSurveyResults() {
      this.resultBtn.text = "Please wait...";
      this.resultBtn.disabled = true;
      this.resultPostData.forEach((question) => {
        question.choices.forEach((choice) => {
          this.resultArray.push({
            question_id: question.survey_question_id,
            choice_id: choice.id,
            offline_evaluation_count: choice.offline_evaluation_count,
          });
        });
      });
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/studfbsurveyresult`;
      await this.$axios
        .post(url, { resultArray: this.resultArray })
        .then((response) => {
          response;
          this.resultBtn.text = "Submit";
          this.resultBtn.disabled = false;
          this.resultArray = [];
          this.$toast.success("Successfully updated.", {
            position: "top",
            duration: 0,
          });
          this.closeCurrentTab();
        })
        .catch((error) => {
          error;
        });
      //  this.clearResponse();
    },

    //********* Getting Survey Details **********/
    async SurveyResult() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          // this.getPoAndPos();
          this.getQuestionChoices();

          response;
        })
        .catch((error) => {
          error;
          error;
        });
    },
    //*********Question and Choices **********/
    async getQuestionChoices() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/studfbsurveys/${this.survey.id}/surveyquestions`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.resultPostData = response.data; // testing-------
          this.resultPostData.forEach((question) => {
            question.survey_question_id = question.id;
          });
          response;
          this.fetchData = false;
        })
        .catch((error) => {
          error;
        });
    },
    clearResponse() {
      this.resultBtn.text = "Update";
      this.surveyAnswers = false;
      setTimeout(() => {
        this.resultBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
